import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`We got our sample!`}</h1>
    <p>{`Remember a month ago - we did a whole series on:`}</p>
    <ul>
      <li parentName="ul">{`Coming up with ideas for an ecom product`}</li>
      <li parentName="ul">{`Validating demand`}</li>
      <li parentName="ul">{`Finding a supplier`}</li>
      <li parentName="ul">{`Etc..`}</li>
    </ul>
    <p>{`I know, that was like 3 pandemics and 2 presidents ago - but the samples are
done baby!!!`}</p>
    <p>{`If you recall, we chose to go into the `}<strong parentName="p">{`kids`}</strong>{` niche, with a `}<strong parentName="p">{`kids play
couch`}</strong>{`. This is a product we want/need at my house, because my daughter is an
absolute menace walking around the house - and adult furniture has all kinds of
sharp corners & is too high for her to climb onto.`}</p>
    <p>{`So we liked the idea of a kids play couch. Did some market research. Found
there’s a big community of buyers for products like this - and decided to take
the plunge.`}</p>
    <p>{`We just got back samples from the factory today - and they look awesome.`}</p>
    <p>{`To be honest, I was worried, I totally though it’d turn out to be a disaster. I
am so used to everything being difficult, that it was a little spooky to have it
all just happen effortlessly.`}</p>
    <p>{`We partnered with Sourcify (shoutout to my guy Nathan, ceo of sourcify for
jumping in on this experiment and helping us find a supplier). And they
basically took care of the whole process. We said what we wanted, they found the
right supplier, and shazam:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1096px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "41%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECA//EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAHexFQD/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAECExH/2gAIAQEAAQUCrrFd560f/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxABAQEBAAAAAAAAAAAAAAAAAEEBQv/aAAgBAQAGPwKrjp//xAAZEAADAAMAAAAAAAAAAAAAAAAAARFBUZH/2gAIAQEAAT8huataFk4QU8j/2gAMAwEAAgADAAAAEIwf/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8QV//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABoQAQEAAwEBAAAAAAAAAAAAAAERACExcYH/2gAIAQEAAT8QpDbWmfMZV3LCvc5T7Dn/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Kids Play Couch Sample",
          "title": "Kids Play Couch Sample",
          "src": "/static/e8c3777ac5fb4707a83f48f4109edead/8bffe/kids-play-couch-sample.jpg",
          "srcSet": ["/static/e8c3777ac5fb4707a83f48f4109edead/a6407/kids-play-couch-sample.jpg 400w", "/static/e8c3777ac5fb4707a83f48f4109edead/a24e6/kids-play-couch-sample.jpg 800w", "/static/e8c3777ac5fb4707a83f48f4109edead/8bffe/kids-play-couch-sample.jpg 1096w"],
          "sizes": "(max-width: 1096px) 100vw, 1096px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Ok - the color is not our actual colors, but the finish, shape, and quality
looks exactly right. These are great because kids can re-arrange them to play in
all different shapes, as well as easily climb/tumble on them without fear of
getting hurt.`}</p>
    <p>{`Now we’re cooking with gas!`}</p>
    <p>{`The main competitor in the space is literally doing a weekly LOTTERY because
more people want to buy them than they can handle. So there’s plenty of demand,
out there waiting to be served by us!`}</p>
    <p>{`There’s just one big problem. The cost to manufacture these is going to be over
`}{`$`}{`100k..`}</p>
    <p>{`That’s a lot of money, and a lot of risk to take on.`}</p>
    <p>{`I think we’re gonna have to pre-sale this baby! If we put these up for presale,
and tell customers it’ll ship in 4-6 weeks, we should be able to sell them
quickly, and finance the order using customer bucks.`}</p>
    <p>{`Black Friday would be a helluva day to do the launch/presale…`}</p>
    <p>{`If we pre-sell 1000 of these at $300 each, that’s $300k in revenue from day 1,
and more than enough to finance the inventory.`}</p>
    <p>{`Nathan from sourcify is big on presales. He’s doing this for his
`}<a parentName="p" {...{
        "href": "http://bubblehotels.com/"
      }}>{`Bubblehotels.com`}</a>{` and for the sweet ass desk he had
made.`}</p>
    <p>{`Looks like he just uses a photoshop to create the mockup, and sells based on
that.`}</p>
    <p>{`So tonight, Ben & I are staying up late trying to get a 3D rendering of this
done so that we can showcase all the different colors & angles of the product on
a website.`}</p>
    <p>{`What’s a 3d rendering? No idea. How is it made? No idea. How much does it cost?
No idea.`}</p>
    <p>{`I’ve never done this before (which is what makes it fun. A new tool in the
toolbelt). So I expect this to take some trial and error.`}</p>
    <p>{`We hit up a few friends asking “who’s your best designer who can do
photorealistic 3d renders?” and got a couple recommendations.`}</p>
    <p>{`Looking at upwork - there’s a few folks to contact here`}</p>
    <p>{`And lastly - I found the perfect search term on google: “3d furniture designer”
and look at this guys shit. Crazy realistic.`}</p>
    <p>{`I also jumped on Design Pickle and put in a bid for a packaging designer for the
box.`}</p>
    <p>{`MASSIVE ACTION!`}</p>
    <p>{`Ok - that’s all for tonight. Exciting times ahead.`}</p>
    <p>{`-shaan`}</p>
    <Links links={[{
      title: LinkLabel.Web,
      links: [{
        url: 'http://bubblehotels.com/',
        label: 'Bubble Hotels'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.i_hate_flying_blind_so_i_made_a_roas_calculator.label,
      slug: slugify(postMap.i_hate_flying_blind_so_i_made_a_roas_calculator.slug)
    }} nextButton={{
      text: postMap.tofu_isnt_just_something_i_like_to_eat.label,
      slug: slugify(postMap.tofu_isnt_just_something_i_like_to_eat.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      